<template>
  <div class="my-6">
    <page-header>
      积分设置
      <template #subtitle>
        在这里,你可以管理您的会员积分设置
      </template>
    </page-header>
    <loading-provider :loading="loading">
      <div class="mt-4">
        <v-card
          outlined
          style="display: grid;grid-gap: 48px;grid-template-columns: repeat(1, minmax(0,1fr));"
        >
          <div>
            <v-card
              :class="!$vuetify.breakpoint.lgAndUp ? 'rounded-tr-0 rounded-tl-0' : ''"
              class="pa-8"
              elevation="0"
            >
              <div class="d-flex align-baseline">
                <div>
                  <div
                    class="text-body-2"
                  >
                    会员积分的开关
                  </div>
                  <div
                    class="text-caption"
                    style="color: grey; max-width: 240px"
                  >
                    控制会员积分,深色开启,浅色关闭
                  </div>
                </div>
                <v-spacer />

                <div
                  class="d-flex justify-end align-baseline"
                  style="width: 50%;max-width: 300px"
                >
                  <v-switch
                    v-model="electronicBillStatus"
                    class="switchClass"
                  />
                </div>
              </div>
              <v-divider class="my-4" />
              <div class="d-flex align-center mt-4">
                <div>
                  <div>{{ $t('PointsSettings') }}</div>
                  <div
                    class="text-caption mt-1"
                    style="color: grey; max-width: 240px"
                  >
                    {{ $t('PintsSettingsHint') }}
                  </div>
                </div>
                <v-spacer />
                <div style="width: 50%;max-width: 300px">
                  <v-text-field
                    v-model="pointPerEuro"
                    dense
                    hide-details
                    outlined
                    persistent-hint
                  />
                </div>
              </div>
              <v-divider class="my-4" />
              <div
                class="d-flex align-center"
              >
                <div>
                  {{ $t('PointRules') }}
                </div>
                <v-spacer />
                <div style="width: 50%;max-width: 300px">
                  <v-select
                    v-model="pointMode"
                    :items="pointModeItems"
                    :menu-props="{outline: true, contentClass:'elevation-2 ikRounded', nudgeBottom: '64px'}"
                    dense
                    hide-details
                    item-text="name"
                    item-value="value"
                    outlined
                    persistent-hint
                  />
                </div>
              </div>
              <v-divider class="my-4" />
              <v-btn
                class="amber lighten-4"
                outlined
                width="100%"
                @click="save"
              >
                {{ $t('save') }}
              </v-btn>
            </v-card>
          </div>
        </v-card>
      </div>
    </loading-provider>
  </div>
</template>

<script>
import PageHeader from '@/views/widget/typography/PageHeader'
import {
  checkElectronicBillingStatus,
  disableElectronicBillingStatus,
  enableElectronicBilling,
  getAllElectronicBillingSetting,
  updateAllElectronicBillingSetting
} from '@/model/dataLayer/api/ElectronicBilling'
import { keyBy } from 'lodash'
import LoadingProvider from '@/views/widget/LoadingProvider'

export default {
  name: 'PointRulesSetting',
  components: {
    LoadingProvider,
    PageHeader
  },
  data () {
    return {
      loading: false,
      storeType: null,
      pointMode: null,
      pointPerEuro: '',
      electronicBillStatus: null,
      // Floor: 向下取整 Ceiling:向上取整  HalfUp: 四舍五入
      pointModeItems: [
        {
          id: 1,
          name: this.$t('Floor'),
          value: 'Floor'
        }, {
          id: 2,
          name: this.$t('Ceiling'),
          value: 'Ceiling'
        }, {
          id: 3,
          name: this.$t('FloorAndCeiling'),
          value: 'FloorAndCeiling'
        }
      ]
      // Merchant:直营 Brand: 连锁
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    async save () {
      this.loading = true
      if (this.electronicBillStatus) {
        await enableElectronicBilling()
      } else {
        await disableElectronicBillingStatus()
      }
      const res = [
        {
          key: 'basicPointType',
          value: this.storeType
        }, {
          key: 'pointPerEuro',
          value: this.pointPerEuro
        }, {
          key: 'roundingMode',
          value: this.pointMode
        }
      ]
      await updateAllElectronicBillingSetting(res)
      this.loading = false
    },
    async reload () {
      this.loading = true
      this.electronicBillStatus = await checkElectronicBillingStatus()
      const res = keyBy((await getAllElectronicBillingSetting()), it => it.key)
      this.storeType = 'Merchant'
      this.pointPerEuro = res.pointPerEuro.value
      this.pointMode = res.roundingMode.value
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
